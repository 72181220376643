(function ($) {
  $(document).on('change', '.js-product-qty-select', function () {
    var $product = $(this).closest('.js-product');
    var $addToBag = $('.js-add-to-cart', $product);
    var newQty = $(this).val() - 0;

    $addToBag.data('qty', newQty);
    $product.trigger('product.qtySelect', [newQty]);
  });
})(jQuery);
